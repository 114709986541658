import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['governingLaw', 'chosenCourts', 'districtOrCounty', 'address', 'selector'];

  static outlets = ['courts-and-jurisdiction'];

  static values = {
    url: String,
  };

  async update() {
    this.subsidiaries = await this.getSelectOptions();
    const selected = await this.findSubsidiary();
    this.subsidiary = selected;
    this.updateAddress(selected);
  }

  async findSubsidiary() {
    const selectedId = this.selectorTarget.value;
    if (!this.subsidiaries) {
      this.subsidiaries = await this.getSelectOptions();
    }
    return this.subsidiaries.find((subsidiary) => subsidiary.id === selectedId);
  }

  updateAddress(selected) {
    this.addressTarget.innerHTML = `${selected.street_address}, ${selected.city}, ${selected.state}, ${selected.zipcode}, ${selected.country}`;
  }

  async getSelectOptions() {
    if (this.subsidiaries) {
      return this.subsidiaries;
    } if (this.hasUrlValue) {
      const response = await fetch(this.urlValue, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      return response.json();
    }
    return null;
  }
}
