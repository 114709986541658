import { Application } from 'stimulus';
import Autosave from 'stimulus-rails-autosave';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import Autocomplete from './autocomplete_controller';

const application = Application.start(document.documentElement);

application.register('autocomplete', Autocomplete);
application.register('autosave', Autosave);
const context = require.context('.', true, /_controller\.js$/);
application.load(definitionsFromContext(context));
