import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['check'];

  check() {
    /* eslint-disable no-param-reassign */
    this.checkTargets.forEach((t) => {
      t.click();
    });
    /* eslint-enable no-param-reassign */
  }
}
