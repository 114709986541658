/* eslint no-param-reassign: ["error", { "props": false }] */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lowerLimit', 'upperLimit'];

  connect() {
    this.recalculateLimits();
  }

  upperLimitTargetConnected() {
    this.recalculateLimits();
  }

  recalculateLimits() {
    const numTargets = this.lowerLimits.length;
    for (let i = 0; i < numTargets; i += 1) {
      const currUpper = getValue(this.upperLimits[i]);
      let newLower;

      if (i === 0) {
        newLower = 1;
      } else {
        const prevUpper = getValue(this.upperLimits[i - 1]);
        newLower = prevUpper + 1;
      }

      this.setValue(this.lowerLimits[i], newLower);
      if (i === numTargets - 1) {
        // Last loop, set upper limit to infinity sign
        this.upperLimits[i].setAttribute('readonly', true);
        this.upperLimits[i].setAttribute('tabindex', '-1');
        this.upperLimits[i].classList.add('text-disabled');
        this.setValue(this.upperLimits[i], null);
      } else {
        this.upperLimits[i].removeAttribute('readonly');
        this.upperLimits[i].removeAttribute('tabindex');
        this.upperLimits[i].classList.remove('text-disabled');
        this.setValue(this.upperLimits[i], Math.max(currUpper, newLower));
      }
    }
  }

  get lowerLimits() {
    return this.lowerLimitTargets.filter((r) => !r.closest('tr').classList.contains('hidden'));
  }

  get upperLimits() {
    return this.upperLimitTargets.filter((r) => !r.closest('tr').classList.contains('hidden'));
  }

  setValue(target, value) {
    const controller = this.application.getControllerForElementAndIdentifier(target, 'cleave');
    if (controller) {
      controller.cleave.setRawValue(value);
    } else {
      target.value = value;
    }
  }
}

function getValue(target) {
  return Number(target.value.replace(/[,|$]/g, ''));
}
