import { displayOnLoad } from './utils';

document.addEventListener('turbolinks:load', () => {
  const agreementDefaultToggles = document.querySelectorAll('.toggle_defaults');
  if (agreementDefaultToggles.length > 0) {
    agreementDefaultToggles.forEach((toggle) => {
      const elementToHide = document.querySelector(`#${toggle.dataset.target}`);
      toggle.addEventListener('click', () => {
        if (elementToHide) {
          elementToHide.classList.toggle('field-hide-display');
        } else {
          console.error(`Element with data target ${toggle.dataset.target} not found`);
        }
      });
    });

    $(document).ready(() => {
      setTimeout(() => {
        displayOnLoad(agreementDefaultToggles, true);
      }, 500);
    });
  }
});
