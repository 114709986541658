export default function modalHelper(target, trigger, close, onHide = () => {}) {
  if ($(target).length) {
    $(target).modal({ backdrop: 'static', keyboard: false });
    $(trigger).click((event) => {
      event.preventDefault();
      $(target).modal('show');
    });

    $(close).click(() => {
      $(target).modal('hide');
      onHide();
    });
  }
}
