import { Controller } from 'stimulus';
import { get, post } from '@rails/request.js';

export default class extends Controller {
  static targets = ['generate', 'message', 'name', 'originalMessage', 'actionButtonsContainer', 'resetButtonContainer', 'loading', 'undo', 'form'];

  async generate() {
    this.generateTarget.textContent = 'Re-generate summary';
    const formData = new FormData(this.formTarget);
    formData.set('_method', 'post');
    await post('/agreements', { query: { update_message: 'true' }, body: formData, responseKind: 'turbo-stream' });
    this.resetButtons();
  }

  async shiftTone({ params: { tone } }) {
    const originalMessage = this.messageTarget.value;

    this.originalMessageTarget.value = originalMessage;
    this.actionButtonsContainerTarget.classList.add('hidden');
    this.resetButtonContainerTarget.classList.remove('hidden');
    this.loadingTarget.classList.remove('hidden');
    this.undoTarget.classList.add('hidden');
    this.disable();

    const response = await post('/tone_shifted_messages', { body: JSON.stringify({ tone_shifted_message: { message: originalMessage, tone } }), responseKind: 'json' });
    if (response.ok) {
      const { id } = await response.json;
      this.pollResponse(id);
    }
  }

  pollResponse(id) {
    const interval = setInterval(async () => {
      const response = await get(`/tone_shifted_messages/${id}`, { responseKind: 'json' });
      if (response.ok) {
        const { status, processed_message: processedMessage } = await response.json;
        if (status === 'complete') {
          clearInterval(interval);
          this.handleResponse(processedMessage);
        }
      }
    }, 1000);
  }

  handleResponse(message) {
    this.messageTarget.value = message;
    this.loadingTarget.classList.add('hidden');
    this.undoTarget.classList.remove('hidden');
    this.enable();
  }

  disable() {
    this.generateTarget.setAttribute('disabled', true);
    this.actionButtonsContainerTarget.querySelectorAll('button').forEach((button) => button.setAttribute('disabled', true));
    this.resetButtonContainerTarget.querySelectorAll('button').forEach((button) => button.setAttribute('disabled', true));
    this.messageTarget.setAttribute('disabled', true);
  }

  enable() {
    this.generateTarget.removeAttribute('disabled');
    this.actionButtonsContainerTarget.querySelectorAll('button').forEach((button) => button.removeAttribute('disabled'));
    this.resetButtonContainerTarget.querySelectorAll('button').forEach((button) => button.removeAttribute('disabled'));
    this.messageTarget.removeAttribute('disabled');
  }

  reset() {
    this.messageTarget.value = this.originalMessageTarget.value;
    this.resetButtons();
  }

  resetButtons() {
    this.originalMessageTarget.value = null;
    this.actionButtonsContainerTarget.classList.remove('hidden');
    this.resetButtonContainerTarget.classList.add('hidden');
  }
}
