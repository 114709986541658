export function removePreview(e) {
  const removeButton = e.target;
  const id = e.target.parentNode.dataset.attachment_id;
  const selectorName = e.target.parentNode.dataset.selector_name;

  const selector = $(`select[name="${selectorName}"]`);
  const selectorOption = $(`[name="${selectorName}"] option[value=${id}]`);

  selectorOption.attr('disabled', false);
  removeButton.parentNode.remove();

  if (singleAttachment(selector)) {
    $(selector).siblings('span.select2').show();
    $(selector).val('').trigger('change');
  }

  // We also have to remove the hidden field so it is not passed as params
  const hiddenFields = document.querySelectorAll(`input[name="${selectorName}"]`);
  const hiddenField = Array.from(hiddenFields).find((field) => field.value === id);
  if (hiddenField) {
    hiddenField.remove();
  }
}

export function addHiddenInput(id, name, form) {
  const hiddenField = document.createElement('input');
  hiddenField.setAttribute('type', 'hidden');
  hiddenField.setAttribute('name', name);
  hiddenField.setAttribute('value', id);
  form.appendChild(hiddenField);
}

export function createRemovableAttachmentPreview(e, id, parentElement, selector) {
  const optionName = e.target.options[e.target.selectedIndex].text;
  const url = `/attachments/${id}.pdf`;
  const preview = document.createElement('div');

  preview.classList.add('attachment-preview', 'select');
  preview.dataset.attachment_id = id;
  preview.dataset.selector_name = selector.name;
  preview.innerHTML = `
         <a href=${url} target="_blank">
         
              <span>${optionName}</span>
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5858 5H11V3L17 3V9H15V6.41418L10.7071 10.7071L9.29285 9.29291L13.5858 5Z" fill="#326F84"/>
                  <path d="M4 4L8 4V6H6L6 14L14 14V12H16V16L4 16L4 4Z" fill="#326F84"/>
                </svg>
              </span>
           
         </a>
        <span class="remove">Remove</span>
        `;
  parentElement.appendChild(preview);
}

function disableSelectorOption(id, selector) {
  if (!singleAttachment(selector)) {
    const option = $(`[name="${selector.name}"] option[value=${id}]`);
    option.attr('disabled', true);
    $(selector).val('').trigger('change');
  }
}

export function disableAfterSelection(id, selector) {
  if (!singleAttachment(selector)) {
    disableSelectorOption(id, selector);
  } else {
    $(selector).siblings('span.select2').hide();
  }
}

export function hideSingleSelector(selector) {
  $(selector).next('span.select2').hide();
}

function singleAttachment(selector) {
  return $(selector).data('single');
}
