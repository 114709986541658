import 'select2';
import * as attachmentSelect from './utils';

document.addEventListener('click', (e) => {
  if (e.target.classList.contains('remove')) {
    attachmentSelect.removePreview(e);
  }
});

export default function initAttachmentSelectors() {
  const attachmentSelector = document.querySelectorAll('.attachment-selectors');
  if (attachmentSelector.length) {
    $('.attachment-selectors').each((i, selector) => {
      const parent = selector.closest('.row-content');
      const { form } = selector;

      if (!$(selector).hasClass('select2-hidden-accessible')) {
        $(selector).on('select2:select', (e) => {
          const id = e.target.value;
          attachmentSelect.createRemovableAttachmentPreview(e, id, parent, selector);
          attachmentSelect.addHiddenInput(id, selector.name, form);
          attachmentSelect.disableAfterSelection(id, selector);
        });

        $(selector).select2({
          width: '100%',
          theme: 'bootstrap-5',
          placeholder: 'Select an attachment from your library',
          minimumResultsForSearch: -1,
        });
      }
    });

    $('[data-hidden=true]').each((i, selector) => {
      attachmentSelect.hideSingleSelector(selector);
    });
  }
}

document.addEventListener('turbolinks:load', () => {
  initAttachmentSelectors();

  $(window).on('add-fields', () => {
    initAttachmentSelectors();
  });
});
