import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['check', 'item'];

  static values = {
    condition: Boolean,
  };

  change() {
    this.checkTargets.forEach((target) => {
      if (target.checked) {
        this.itemTarget.disabled = false;
        this.itemTarget.classList.remove('cursor-not-allowed');
      } else if (this.conditionValue === true) {
        this.itemTarget.disabled = true;
        this.itemTarget.classList.add('cursor-not-allowed');
      }
    });
  }
}
