document.addEventListener('turbolinks:load', () => {
  const inputsToCheck = document.querySelectorAll('.check_on_input');

  if (inputsToCheck) {
    inputsToCheck.forEach((input) => {
      const checkbox = document.querySelector(`#${input.dataset.target}`);
      input.addEventListener('keydown', () => {
        if (!checkbox.checked) checkbox.checked = true;
      });
      input.addEventListener('change', () => {
        if (!checkbox.checked) checkbox.checked = true;
      });
    });
  }
});
