import 'select2';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selector'];

  static values = {
    url: String,
    enabled: Boolean,
  };

  connect() {
    if (!this.enabledValue) return;
    $(this.selectorTarget).on('select2:open', () => {
      $('.select2-results:not(:has(a))').append(uploadNewAttachmentButton(this.urlValue));
    });
  }
}

function uploadNewAttachmentButton(url) {
  return `<hr class="m-0"><div class="px-5 py-4"><a data-turbo=true data-turbo-frame="turbo_modal" href=${url}>Upload new attachment</a></div>`;
}
