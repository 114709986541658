import modalHelper from './modalHelper';

document.addEventListener('turbolinks:load', () => {
  function onHide() {
    if ($('#termsModal').length && $('#termsModal').data('accepted') === false) {
      $('#termsModal').modal('show');
    }
  }
  modalHelper('#agreementReassignModal', '.agreement_reassign_link', '.agreement-reassign-modal-cancel-button', onHide);
});
