import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  enable() {
    this.itemTargets.forEach((item) => {
      item.removeAttribute('disabled');
    });
  }

  disable() {
    this.itemTargets.forEach((item) => {
      item.addAttribute('disabled');
    });
  }
}
