import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submit', 'underlyingAgreement', 'chosenAgreement'];

  static values = {
    alwaysDisable: Boolean,
  };

  connect() {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    const underlyingAgreementSelected = this.underlyingAgreementTargets.some(
      (element) => element.id === 'include_underlying_agreement_true' && element.checked,
    );
    const underlyingAgreementChosen = this.chosenAgreementTargets.some(
      (element) => element.selectedIndex !== 0,
    );
    const newAgreementSelected = this.underlyingAgreementTargets.some(
      (element) => element.id === 'include_underlying_agreement_false' && element.checked,
    );

    // Enable if we made the proper selections, but always remain disabled if we are at the plan limit
    if (((underlyingAgreementSelected && underlyingAgreementChosen)
      || newAgreementSelected) && !this.alwaysDisableValue) {
      this.submitTarget.removeAttribute('disabled');
      this.submitTarget.classList.remove('cursor-not-allowed');
    } else {
      this.submitTarget.setAttribute('disabled', 'true');
      this.submitTarget.classList.add('cursor-not-allowed');
    }
  }
}
