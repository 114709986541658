document.addEventListener('turbolinks:load', () => {
  const standardTermsToggle = document.querySelector('#standardTermsToggle');
  const standardTermsContainer = document.querySelector('.standard-terms-container');

  if (standardTermsToggle) {
    standardTermsToggle.addEventListener('change', () => {
      standardTermsContainer.classList.toggle('field-hide-display');
    });
  }
});
