/* eslint no-new: "off", no-undef: "off", class-methods-use-this: "off" */
import { Controller } from 'stimulus';
import { formatMoneyDecimal, parseMoneyInput } from '../src/utils/currency';

function calculateCost(costElements) {
  let totalCost = 0;
  costElements.forEach((costElement) => {
    const quantity = parseMoneyInput(costElement.querySelector('[data-quantity="true"]').value);
    const cost = parseMoneyInput(costElement.querySelector('[data-currency="true"]').value);
    const total = quantity * cost;
    if (total) {
      totalCost += total;
    }
  });

  return totalCost;
}

function calculateDiscount(discountElements) {
  let totalDiscount = 0;

  discountElements.forEach((discountElement) => {
    const discount = parseMoneyInput(discountElement.querySelector('[data-currency="true"]').value);

    if (discount) {
      totalDiscount += discount;
    }
  });
  return totalDiscount;
}

function updateTotal(costTargets, discountTargets, meteredTargets) {
  const cost = calculateCost(costTargets);
  const discount = calculateDiscount(discountTargets);
  let totalAmount = `<strong><span data-currency-target='symbol'>$</span><span data-stripe-billing-preview-target='total'>${formatMoneyDecimal(cost - discount)}</span></strong>`;
  if (meteredTargets.length !== 0) {
    totalAmount += '<p>+ metered items';
  }
  return totalAmount;
}

const TOTAL_ROW_HTML = `<tr>
        <td colspan="8"></td>
        <td colspan="1" data-fee-target="total" class="total"><strong></strong></td>
        <td>
          <a class="remove-fields" data-action="fee#removeTotalRow">X</a>
        </td>
      </tr>`;

const TOTAL_ADD_HTML = `<tr>
        <td colspan="9">
            <a data-action="fee#addTotalRow">Add total</a>
        </td>
      </tr>`;

export default class extends Controller {
  static targets = ['cost', 'discount', 'metered', 'included', 'total', 'optionalTotal', 'optionalTotalRow'];

  static outlets = ['stripe-billing-preview'];

  totalTargetConnected() {
    this.updateTotal();
  }

  updateTotal() {
    if (this.hasTotalTarget) {
      this.totalTarget.innerHTML = updateTotal(this.costTargets, this.discountTargets, this.meteredTargets);
    }
    if (this.hasStripeBillingPreviewOutlet) {
      this.stripeBillingPreviewOutlet.updatePreview();
    }
  }

  removeRowAndUpdateTotal(e) {
    e.target.closest('tr').removeAttribute('data-fee-target');
    if (this.hasTotalTarget) {
      this.totalTarget.innerHTML = updateTotal(this.costTargets, this.discountTargets, this.meteredTargets);
    }
  }

  addTotalRow() {
    this.optionalTotalTarget.value = true;
    this.optionalTotalRowTarget.innerHTML = TOTAL_ROW_HTML;
    this.totalTarget.innerHTML = updateTotal(this.costTargets, this.discountTargets, this.meteredTargets);
  }

  removeTotalRow() {
    this.optionalTotalTarget.value = false;
    this.optionalTotalRowTarget.innerHTML = TOTAL_ADD_HTML;
  }
}
