document.addEventListener('turbolinks:load', () => {
  if ($('[data-toggle=tooltip]').length) {
    $('[data-toggle=tooltip]').each((i, tooltipObject) => {
      const customClass = tooltipObject.classList[0];
      const placement = $(tooltipObject).data('placement');

      $(tooltipObject).tooltip({
        placement, customClass, html: true, trigger: 'manual',
      }).on('mouseenter', function showTooltip() {
        const tooltip = this;
        $(this).tooltip('show');
        $('.tooltip').on('mouseleave', () => {
          $(tooltip).tooltip('hide');
        });
      }).on('mouseleave', function hideTooltip() {
        const tooltip = this;
        if (!$('.tooltip:hover').length) {
          $(tooltip).tooltip('hide');
        }
      });
    });
  }
});
