import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static values = {
    dismissable: { type: Boolean, default: true },
  };

  connect() {
    if (this.dismissableValue) {
      this.modal = new Modal(this.element);
    } else {
      const options = { backdrop: 'static', keyboard: false };
      this.modal = new Modal(this.element, options);
    }
    this.modal.show();
  }

  hideBeforeRender(event) {
    event.preventDefault();
    this.element.addEventListener('hidden.bs.modal', event.detail.resume);
    this.modal.hide();
  }

  hideModal() {
    this.hideForRerender();
    this.element.remove();
  }

  hideForRerender() {
    this.modal.hide();
    this.element.parentElement.removeAttribute('src');
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }
}
