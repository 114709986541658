import { Controller } from 'stimulus';
import { useClickOutside } from 'stimulus-use';
import {
  onArrowDownKeydown, onArrowUpKeydown, onEnterKeydown, onEscapeKeydown, onKeydown, onTabKeydown,
} from '../src/utils/keyboard_shortcuts';

const optionSelector = "[role='option']:not([aria-disabled])";
const activeSelector = "[aria-selected='true']";

const debounce = (fn, delay = 10) => {
  let timeoutId = null;

  return () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, delay);
  };
};

const optionsForFetch = { headers: { 'X-Requested-With': 'XMLHttpRequest' } };

const doFetch = async (url) => {
  const response = await fetch(url, optionsForFetch);

  if (!response.ok) {
    throw new Error(`Server responded with status ${response.status}`);
  }

  return response.text();
};

export default class Combobox extends Controller {
  static targets = ['button', 'text', 'box', 'hidden', 'search', 'results'];

  static classes = ['selected'];

  static values = {
    placeholder: String,
    ready: Boolean,
    submitOnEnter: Boolean,
    url: String,
    minLength: Number,
    delay: { type: Number, default: 300 },
    queryParam: { type: String, default: 'q' },
  };

  static uniqOptionId = 0;

  connect() {
    this.close();

    if (!this.searchTarget.hasAttribute('autocomplete')) this.searchTarget.setAttribute('autocomplete', 'off');
    this.searchTarget.setAttribute('spellcheck', 'false');

    this.mouseDown = false;

    this.onInputChange = debounce(this.onInputChange, this.delayValue);

    this.buttonTarget.addEventListener('click', this.toggle);
    // TODO: Maybe remove
    // this.buttonTarget.addEventListener('keydown', this.onButtonKeydown);
    this.searchTarget.addEventListener('keydown', this.onKeydown);
    this.searchTarget.addEventListener('blur', this.onInputBlur);
    this.searchTarget.addEventListener('input', this.onInputChange);
    this.resultsTarget.addEventListener('mousedown', this.onResultsMouseDown);
    this.resultsTarget.addEventListener('click', this.onResultsClick);

    if (this.searchTarget.hasAttribute('autofocus')) {
      this.searchTarget.focus();
    }

    this.readyValue = true;
    useClickOutside(this);
  }

  disconnect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener('click', this.toggle);
    }
    if (this.hasItemTargets) {
      this.itemTargets.forEach((item) => {
        item.removeEventListener('click', (event) => this.onCommit(event.target));
      });
    }
    if (this.hasInputTarget) {
      this.searchTarget.removeEventListener('keydown', this.onKeydown);
      this.searchTarget.removeEventListener('blur', this.onInputBlur);
      this.searchTarget.removeEventListener('input', this.onInputChange);
    }

    if (this.hasResultsTarget) {
      this.resultsTarget.removeEventListener('mousedown', this.onResultsMouseDown);
      this.resultsTarget.removeEventListener('click', this.onResultsClick);
    }
  }

  clickOutside() {
    this.close();
  }

  reset(event) {
    if (this.hasPlaceholderValue) {
      this.hiddenTarget.value = null;
      this.element.classList.remove('active');
      if (this.hasTextTarget) {
        this.textTarget.innerHTML = this.placeholderValue;
      }
      this.buttonTarget.querySelector('#icon-dropdown')?.classList.remove('hidden');
      this.buttonTarget.querySelector('#icon-reset')?.classList.add('hidden');
      this.hiddenTarget.dispatchEvent(new Event('change'));
      this.searchTarget.value = '';
    } else {
      this.onCommit(this.itemTargets[0]);
    }
    if (event) {
      event.stopPropagation();
    }
  }

  itemTargetConnected(target) {
    target.addEventListener('click', (event) => this.onCommit(event.target));
  }

  sibling(next) {
    const { options } = this;
    const selected = this.selectedOption;
    const index = options.indexOf(selected);
    const sibling = next ? options[index + 1] : options[index - 1];
    const def = next ? options[0] : options[options.length - 1];
    return sibling || def;
  }

  select(target) {
    const previouslySelected = this.selectedOption;
    if (previouslySelected) {
      previouslySelected.removeAttribute('aria-selected');
      previouslySelected.classList.remove(...this.selectedClassesOrDefault);
    }

    target.setAttribute('aria-selected', 'true');
    target.classList.add(...this.selectedClassesOrDefault);
    this.searchTarget.setAttribute('aria-activedescendant', target.id);
    target.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  }

  onKeydown = (event) => onKeydown(this, event);

  onEscapeKeydown = (event) => onEscapeKeydown(this, event);

  onArrowDownKeydown = (event) => onArrowDownKeydown(this, event);

  onArrowUpKeydown = (event) => onArrowUpKeydown(this, event);

  onTabKeydown = () => onTabKeydown(this);

  onEnterKeydown = (event) => onEnterKeydown(this, event);

  onInputBlur = () => {
    if (this.mouseDown) return;
    this.close();
  };

  onCommit(selected) {
    if (selected.getAttribute('aria-disabled') === 'true') return;

    if (selected instanceof HTMLAnchorElement) {
      selected.click();
      this.close();
      return;
    }

    const textValue = selected.getAttribute('data-combobox-label') || selected.textContent.trim();
    const value = selected.getAttribute('data-combobox-value') || textValue;

    if (this.hasTextTarget) {
      this.textTarget.innerHTML = textValue;
    }
    this.searchTarget.value = '';
    this.element.classList.add('active');
    if (this.hasPlaceholderValue) {
      this.buttonTarget.querySelector('#icon-dropdown')?.classList.add('hidden');
      this.buttonTarget.querySelector('#icon-reset')?.classList.remove('hidden');
    }

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value;
      this.hiddenTarget.dispatchEvent(new Event('input'));
      this.hiddenTarget.dispatchEvent(new Event('change'));
    } else {
      this.searchTarget.value = value;
    }

    this.searchTarget.focus();
    this.hideAndRemoveOptions();
    this.close();

    this.element.dispatchEvent(
      new CustomEvent('combobox.change', {
        bubbles: true,
        detail: { value, textValue, selected },
      }),
    );
  }

  clear() {
    this.searchTarget.value = '';
    if (this.hasHiddenTarget) this.hiddenTarget.value = '';
  }

  onResultsClick = (event) => {
    if (!(event.target instanceof Element)) return;
    const selected = event.target.closest(optionSelector);
    if (selected) this.onCommit(selected);
  };

  onResultsMouseDown = () => {
    this.mouseDown = true;
    this.resultsTarget.addEventListener('mouseup', () => {
      this.mouseDown = false;
    }, { once: true });
  };

  onInputChange = () => {
    if (this.hasHiddenTarget) this.hiddenTarget.value = '';

    const query = this.searchTarget.value.trim();
    if (query && query.length >= this.minLengthValue) {
      this.fetchResults(query);
    } else {
      this.hideAndRemoveOptions();
    }
  };

  identifyOptions() {
    const prefix = this.resultsTarget.id || 'stimulus-autocomplete';
    const optionsWithoutId = this.resultsTarget.querySelectorAll(`${optionSelector}:not([id])`);
    // eslint-disable-next-line no-param-reassign,no-return-assign,no-plusplus
    optionsWithoutId.forEach((el) => el.id = `${prefix}-option-${Combobox.uniqOptionId++}`);
  }

  hideAndRemoveOptions() {
    this.close();
    this.resultsTarget.innerHTML = null;
  }

  fetchResults = async (query) => {
    if (!this.hasUrlValue) return;

    const url = this.buildURL(query);
    try {
      this.element.dispatchEvent(new CustomEvent('loadstart'));
      const html = await doFetch(url);
      this.replaceResults(html);
      this.element.dispatchEvent(new CustomEvent('load'));
      this.element.dispatchEvent(new CustomEvent('loadend'));
    } catch (error) {
      this.element.dispatchEvent(new CustomEvent('error'));
      this.element.dispatchEvent(new CustomEvent('loadend'));
      throw error;
    }
  };

  buildURL(query) {
    const url = new URL(this.urlValue, window.location.href);
    const params = new URLSearchParams(url.search.slice(1));
    params.append(this.queryParamValue, query);
    url.search = params.toString();

    return url.toString();
  }

  replaceResults(html) {
    this.resultsTarget.innerHTML = html;
    this.identifyOptions();
    if (this.options) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    if (this.expanded && this.resultsShown) return;

    this.expanded = true;
    this.resultsShown = true;
    this.boxTarget.classList.remove('hidden');
    this.element.setAttribute('aria-expanded', 'true');
    this.element.dispatchEvent(
      new CustomEvent('toggle', {
        detail: { action: 'open', searchTarget: this.searchTarget, resultsTarget: this.resultsTarget },
      }),
    );
  }

  close() {
    if (!this.expanded || !this.resultsShown) return;

    this.expanded = false;
    this.resultsShown = false;
    this.searchTarget.removeAttribute('aria-activedescendant');
    this.element.setAttribute('aria-expanded', 'false');
    this.boxTarget.classList.add('hidden');
    this.buttonTarget.focus();
    this.element.dispatchEvent(
      new CustomEvent('toggle', {
        detail: { action: 'close', searchTarget: this.searchTarget, resultsTarget: this.resultsTarget },
      }),
    );
  }

  toggle = () => {
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  };

  get resultsShown() {
    return !this.resultsTarget.hidden;
  }

  set resultsShown(value) {
    this.resultsTarget.hidden = !value;
  }

  get options() {
    return Array.from(this.resultsTarget.querySelectorAll(optionSelector));
  }

  get selectedOption() {
    return this.resultsTarget.querySelector(activeSelector);
  }

  get selectedClassesOrDefault() {
    return this.hasSelectedClass ? this.selectedClasses : ['active'];
  }
}
