const CAROUSEL_DATA = {
  csa_cloud_description: [
    'Dynalitics is a cloud-based analytics platform that automatically charts your key business metrics. This agreement includes the Dynalitics Starter package: automatic dashboards and Dynalitics data integrations.',
    'TriangleCI is a continuous integration & delivery platform that helps development teams release code rapidly and automate build, test, and deploy.',
    'SellPower includes customer relationship management software and applications focused on sales, customer service, marketing automation, analytics, and application development.',
  ],
  software_license_description: [
    'Picturestore is desktop image editing software that enhances photographs and creates new designs from scratch.',
    'Soothsayer is a powerful database that supports high transaction volumes, large data sets, and strong transactional guarantees. This agreement includes both the OLTP and OLAP modules.',
    'Camus is an event streaming platform that can be used to create high-performance data pipelines and integration disparate applications. This agreement includes the open source Camus core as well as Camus enterprise, which adds auditing, access control, and enhanced reporting.',
  ],

};

class Carousel {
  constructor() {
    this.type = null;
    this.index = 0;
    this.setCarousel();
    this.initializeEventListeners();
  }

  setCarousel() {
    this.carouselContent = document.querySelector('[data-carousel]');
    if (this.carouselContent) {
      this.type = this.carouselContent.dataset.carousel;
      this.setCarouselText();
    }
  }

  setCarouselText() {
    const text = CAROUSEL_DATA[this.type][this.index];
    if (this.carouselContent.firstChild) {
      this.carouselContent.firstChild.nodeValue = text;
    } else {
      this.carouselContent.appendChild(document.createTextNode(text));
    }
  }

  initializeEventListeners() {
    const next = document.querySelector('.carousel_next');
    const previous = document.querySelector('.carousel_previous');
    if (this.carouselContent && next && previous) {
      next.addEventListener('click', () => this.increment());
      previous.addEventListener('click', () => this.decrement());
    }
  }

  increment() {
    if (this.index >= this.lastIndex()) {
      this.index = 0;
    } else {
      this.index += 1;
    }
    this.setCarouselText();
  }

  decrement() {
    if (this.index === 0) {
      this.index = this.lastIndex();
    } else {
      this.index -= 1;
    }
    this.setCarouselText();
  }

  lastIndex() {
    return CAROUSEL_DATA[this.type].length - 1;
  }
}

window.addEventListener('turbolinks:load', () => new Carousel());
