import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selector', 'frame'];

  static values = {
    name: String,
    url: String,
    queryParams: Object,
    frameType: String,
  };

  updateTurbo() {
    let url;
    if (this.hasUrlValue) {
      url = this.urlValue;
    } else {
      url = this.selectedElement.dataset.url;
    }

    if (url) {
      const parsedUrl = new URL(url);
      if (this.hasQueryParamsValue) {
        Object.entries(this.queryParamsValue).forEach(([key, value]) => {
          parsedUrl.searchParams.append(key, value);
        });
      } else if (this.hasNameValue) {
        parsedUrl.searchParams.append(this.nameValue, this.selectedElement.value);
      }
      if (this.hasFrameTarget) {
        this.frameTarget.src = parsedUrl.toString();
      } else if (this.hasFrameTypeValue) {
        if (this.frameTypeValue === 'modal') {
          document.getElementById('turbo_modal').src = parsedUrl.toString();
        }
      }
    }
  }

  get selectedElement() {
    return this.selectorTarget.options[this.selectorTarget.selectedIndex];
  }
}
