import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'search', 'clear'];

  clear() {
    this.inputTarget.value = '';
    this.searchTarget.classList.remove('hidden');
    this.clearTarget.classList.add('hidden');
  }

  update(event) {
    if (event.target.value.length) {
      this.searchTarget.classList.add('hidden');
      this.clearTarget.classList.remove('hidden');
    } else {
      this.searchTarget.classList.remove('hidden');
      this.clearTarget.classList.add('hidden');
    }
  }
}
