import modalHelper from './modalHelper';

document.addEventListener('turbolinks:load', () => {
  const openModalButtons = document.querySelectorAll('.open_modal');

  if (openModalButtons) {
    openModalButtons.forEach((button) => {
      const targetModal = `#${button.dataset.target}`;
      const cancelButton = `#${button.dataset.target}Cancel`;
      modalHelper(targetModal, button, cancelButton);
    });
  }
});
