/* eslint no-param-reassign: ["error", { "props": false }] */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['destroy'];

  mark() {
    this.destroyTarget.value = true;
    this.element.classList.add('hidden');
  }
}
