export default function oneOrMultipleValidation(checkboxParents, event) {
  const ERROR = 'You must check at least one';
  let scrollPoint = null;
  let isValid = true;

  checkboxParents.forEach((parentNode) => {
    // The data-should-validate attribute lets us condition the multi-checkbox validator on another
    // field being checked.  In other words, if a data-should-validate element is provided, and
    // that element is not checked, we will not run the multi-checkbox validation.
    const { shouldValidate } = parentNode.dataset;
    if (shouldValidate) {
      const shouldValidateTarget = document.querySelector(shouldValidate);
      if (!shouldValidateTarget?.checked) {
        return;
      }
    }
    const checkboxNodes = parentNode.querySelectorAll('input[type=checkbox]');
    if (hasCheckboxes(checkboxNodes) && hasNoneChecked(checkboxNodes)) {
      if (isValid) {
        isValid = false;
      }
      if (!scrollPoint) {
        scrollPoint = parentNode.parentNode;
      }
      addErrorMessage(parentNode);
    } else {
      removeErrorMessage(parentNode);
    }
  });

  if (!isValid) {
    event.preventDefault();
    scrollPoint.scrollIntoView();
  }

  function hasNoneChecked(checkboxNodes) {
    return ![...checkboxNodes].find((node) => node.checked);
  }

  function hasCheckboxes(checkboxNodes) {
    return Boolean(checkboxNodes.length);
  }

  function addErrorMessage(parentNode) {
    const hasError = parentNode.querySelector('.inline-error');
    if (!hasError) {
      const errorMessage = document.createElement('p');
      const errorText = document.createTextNode(ERROR);
      errorMessage.appendChild(errorText);
      errorMessage.classList.add('inline-error');
      parentNode.prepend(errorMessage);
    }
  }

  function removeErrorMessage(parentNode) {
    const hasError = parentNode.querySelector('.inline-error');
    if (hasError) {
      hasError.parentNode.removeChild(hasError);
    }
  }
}
