/* eslint no-new: "off", no-undef: "off" */
document.addEventListener('turbolinks:load', () => {
  // Input Mask
  document.querySelectorAll('input.currency-input-mask').forEach((el) => {
    new Cleave(el, window.AMOUNT_INPUT_MASK);
  });

  document.querySelectorAll('input.currency-thousand-mask').forEach((el) => {
    new Cleave(el, window.THOUSAND_WITHOUT_DECIMALS);
  });

  document.querySelectorAll('input.currency-input-decimal-mask').forEach((el) => {
    new Cleave(el, window.LONG_DECIMAL_INPUT_MASK);
  });
});
