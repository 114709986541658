class RemoveFields {
  constructor() {
    RemoveFields.iterateLinks();
  }

  static iterateLinks() {
    // Use event delegation to ensure any fields added after the page loads are captured.
    document.addEventListener('click', (e) => {
      const containerToRemove = e?.target.closest('.remove-fields');
      if (containerToRemove) {
        e.preventDefault();
        RemoveFields.handleClick(containerToRemove);
      }
    });
  }

  static handleClick(link) {
    const fieldParent = link.closest(`#${link.dataset.target}`);
    const deleteField = fieldParent
      ? fieldParent.querySelector('._destroy')
      : null;
    if (deleteField) {
      const form = $('.agreement_edit_form')[0];
      deleteField.setAttribute('value', true);
      fieldParent.style.display = 'none'; // eslint-disable-line no-param-reassign
      if (form) form.dispatchEvent(new Event('input'));
    }
  }
}

window.addEventListener('turbolinks:load', () => new RemoveFields());
