document.addEventListener('turbolinks:load', () => {
  if ($('#termsModal').length) {
    $('#termsModal').modal({ backdrop: 'static', keyboard: false });
    $('#termsModal').modal('show');

    $('#reassignAgreement').click((event) => {
      event.preventDefault();
      $('#termsModal').modal('hide');
      $('#agreementReassignModal').modal('show');
    });
  }
});
