import { Controller } from 'stimulus';
import { useClickOutside } from 'stimulus-use';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static targets = ['input'];

  static values = { url: String, frame: String };

  connect() {
    useClickOutside(this);
  }

  clickOutside() {
    this.save();
  }

  async save(event) {
    const pressedEnter = Boolean(event);
    if (pressedEnter) event.preventDefault();
    try {
      const res = await patch(this.urlValue, {
        body: JSON.stringify({ name: this.inputTarget.value }),
        responseKind: 'html',
      });
      if (res.ok) {
        const data = await res.html;

        const item = document.getElementById(this.frameValue);
        item.outerHTML = data;
      }
    } catch (error) {
      console.error(error?.message);
    }
  }
}
