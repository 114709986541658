import { Controller } from 'stimulus';
import LANGUAGE_LIBRARY from '../../assets/data/language_library.json';

export default class extends Controller {
  static targets = ['input', 'item', 'search'];

  static values = {
    version: String,
    type: String,
  };

  connect() {
    if (this.hasInputTarget) {
      this.entries = LANGUAGE_LIBRARY[this.typeValue].reduce((entries, { section, clauses }) => {
        const compatibleClauses = clauses.filter((clause) => this.hasCompatibleVersion(clause));
        if (compatibleClauses.length) {
          entries.push({ section, clauses: compatibleClauses });
        }
        return entries;
      }, []);
    }
  }

  filter(event) {
    const container = event.target.closest('.column');
    this.entries.forEach(({ clauses, section }) => {
      /* eslint-disable prefer-const */
      let sectionHasMatches = false;
      let sectionKey = makeKey(section);
      let sectionElement = container.querySelector(`#${sectionKey}`);
      clauses.forEach(({ title, clause }) => {
        let key = makeKey(title);
        let element = container.querySelector(`#${key}`);
        if (this.includesSearchValue(title) || this.includesSearchValue(clause)) {
          element.classList.remove('hidden');
          sectionHasMatches = true;
        } else {
          element.classList.add('hidden');
        }
      });
      /* eslint-enable prefer-const */
      if (sectionHasMatches) {
        sectionElement.classList.remove('hidden');
      } else {
        sectionElement.classList.add('hidden');
      }
    });
  }

  insert(event) {
    this.inputTarget.value = this.insertedText(event.target.value);
  }

  insertedText(value) {
    return this.inputTarget.value.length ? `${this.inputTarget.value}\n\n${value}` : value;
  }

  searchValue() {
    return this.searchTarget.value.toLowerCase();
  }

  includesSearchValue(text) {
    return text.toLowerCase().includes(this.searchValue());
  }

  hasCompatibleVersion(clause) {
    return Boolean(clause.compatible_versions.find((version) => String(version) === this.versionValue));
  }
}

function makeKey(title) {
  return title.replace(/[^0-9A-Za-z]+/g, '');
}
