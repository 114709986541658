import { displayOnLoad } from './utils';

document.addEventListener('turbolinks:load', () => {
  const checkBoxes = document.querySelectorAll('.expanding_checkbox');
  if (checkBoxes.length) {
    checkBoxes.forEach((checkBox) => {
      const elementToHide = document.querySelector(`#${checkBox.dataset.target}`);
      checkBox.addEventListener('click', () => {
        if (!checkBox.checked) {
          elementToHide.classList.add('field-hide-display');
        } else {
          elementToHide.classList.remove('field-hide-display');
        }
      });
    });

    $(document).ready(() => {
      setTimeout(() => {
        displayOnLoad(checkBoxes, false);
      }, 100);
    });
  }
});
