import { Controller } from 'stimulus';
import HelloSign from 'hellosign-embedded';

export default class extends Controller {
  static values = { url: String, clientId: String, successUrl: String };

  static targets = ['container'];

  connect() {
    window.addEventListener('beforeunload', (event) => {
      event.stopImmediatePropagation();
    });

    const client = new HelloSign({ clientId: this.clientIdValue, allowCancel: false });
    client.open(
      unescape(this.urlValue),
      { skipDomainVerification: true, container: this.containerTarget },
    );
    client.on('createTemplate', (data) => {
      const url = new URL(this.successUrlValue);
      url.searchParams.append('template_id', data.templateId);
      window.location.replace(url);
    });
  }
}
