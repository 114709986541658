import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['radio', 'selector', 'item'];

  update() {
    this.itemTargets.forEach((item) => {
      if (this.selectedElement.value === item.dataset.value) {
        item.classList.remove('hidden');
        item.removeAttribute('disabled');
      } else {
        item.classList.add('hidden');
        item.setAttribute('disabled', 'true');
      }
    });
  }

  get selectedElement() {
    if (this.hasSelectorTarget) {
      return this.selectorTarget.options[this.selectorTarget.selectedIndex];
    } if (this.hasRadioTarget) {
      return this.radioTargets.find((t) => t.checked);
    }
    console.error('No selector or radio targets defined');
    return null;
  }
}
