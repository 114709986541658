import modalHelper from './modalHelper';

document.addEventListener('turbolinks:load', () => {
  function onHide() {
    if ($('#termsModal').length && $('#termsModal').data('accepted') === false) {
      $('#termsModal').modal('show');
    }
  }
  modalHelper('#sendForSignatureModal', '.send_for_signature_link', '.send-for-signature-modal-cancel-button', onHide);
});
