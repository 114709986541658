import { Controller } from 'stimulus';
import { STATES_HTML } from '../src/utils';

export default class extends Controller {
  static targets = ['autocompleteController', 'hidden', 'country', 'address', 'city', 'state', 'zip', 'cityLabel', 'stateLabel', 'zipLabel'];

  autocomplete() {
    const { value } = this.hiddenTarget;
    if (this.countryTarget.value === 'USA') {
      this.usAutocomplete(value);
    } else {
      this.internationalAutocomplete(value);
    }
  }

  usAutocomplete(value) {
    const address = JSON.parse(value);
    if (address.city) {
      this.cityTarget.value = address.city;
    }
    if (address.state) {
      this.stateTarget.value = address.state;
    }
    if (address.zipcode) {
      this.zipTarget.value = address.zipcode;
    }
    if (address.entries > 1) {
      const autocompleteController = this.application.getControllerForElementAndIdentifier(this.autocompleteControllerTarget, 'autocomplete');
      autocompleteController.onInputChange(0);
    } else {
      this.addressTarget.value = address.street_line;
      if (address.secondary) {
        this.addressTarget.value += ` ${address.secondary}`;
      }
    }
  }

  internationalAutocomplete(value) {
    const data = JSON.parse(value);
    if (data.address_id) {
      const autocompleteController = this.application.getControllerForElementAndIdentifier(this.autocompleteControllerTarget, 'autocomplete');
      autocompleteController.fetchResults(autocompleteController.hiddenTarget.value);
    } else {
      const address = JSON.parse(value);
      if (address.city) {
        this.cityTarget.value = address.city;
      }
      if (address.state) {
        this.stateTarget.value = address.state;
      }
      if (address.zipcode) {
        this.zipTarget.value = address.zipcode;
      }
      this.addressTarget.value = address.street_line;
      if (address.secondary) {
        this.addressTarget.value += ` ${address.secondary}`;
      }
    }
  }

  clearFields() {
    this.addressTarget.value = '';
    this.cityTarget.value = '';
    this.zipTarget.value = '';
  }

  updateStateField() {
    const classes = this.stateTarget.classList;
    if (this.countryTarget.value === 'USA') {
      classes.add('form-select');
      this.stateTarget.outerHTML = `<select id=${this.stateTarget.id} name=${this.stateTarget.name} data-address-target="state" data-copy-from-target="input" data-action="copy-from#copy" placeholder="Administrative region">`;
      this.stateTarget.innerHTML = STATES_HTML;
    } else {
      classes.remove('form-select');
      this.stateTarget.outerHTML = `<input id=${this.stateTarget.id} name=${this.stateTarget.name} type="text" data-address-target="state" data-copy-from-target="item" data-action="copy-from#copy" placeholder="Administrative region">`;
    }
    this.stateTarget.classList = classes;
  }

  updateFields() {
    const autocompleteController = this.application.getControllerForElementAndIdentifier(this.autocompleteControllerTarget, 'autocomplete');

    if (this.countryTarget.value === 'USA') {
      if (this.hasCityLabelTarget) {
        this.cityLabelTarget.innerText = 'City';
      }
      if (this.hasStateLabelTarget) {
        this.stateLabelTarget.innerText = 'State';
      }
      if (this.hasZipLabelTarget) {
        this.zipLabelTarget.innerText = 'ZIP';
      }
      this.cityTarget.placeholder = 'City';
      this.zipTarget.placeholder = 'ZIP';
    } else {
      if (this.hasCityLabelTarget) {
        this.cityLabelTarget.innerText = 'Locality';
      }
      if (this.hasStateLabelTarget) {
        this.stateLabelTarget.innerText = 'Administrative region';
      }
      if (this.hasZipLabelTarget) {
        this.zipLabelTarget.innerText = 'Postal code';
      }
      this.cityTarget.placeholder = 'Locality';
      this.zipTarget.placeholder = 'Postal code';
    }
    autocompleteController.urlValue = `/addresses?country=${this.countryTarget.value}`;
    this.updateStateField();
    this.clearFields();
  }
}
