/* eslint no-param-reassign: ["error", { "props": false }] */
import { Controller } from 'stimulus';

function resetField(field) {
  switch (field.type.toLowerCase()) {
    case 'text':
    case 'number':
    case 'password':
    case 'textarea':
    case 'hidden':
      field.value = '';
      break;
    case 'radio':
    case 'checkbox':
      if (field.checked) {
        field.checked = false;
      }
      break;
    case 'select-one':
    case 'select-multi':
      field.selectedIndex = -1;
      break;
    default:
      break;
  }
}

export default class extends Controller {
  static targets = ['item'];

  reset() {
    const event = new CustomEvent('reset');
    window.dispatchEvent(event);
    this.itemTargets.forEach((item) => {
      resetField(item);
    });
  }
}
