document.addEventListener('turbolinks:load', colorDateField);

document.addEventListener('turbo:frame-load', colorDateField);

function colorDateField() {
  const dateInputs = document.querySelectorAll('input[type=date]');
  dateInputs.forEach((date) => {
    // eslint-disable-next-line no-param-reassign

    if (date.value !== '') {
      date.classList.add('default');
    }

    // eslint-disable-next-line no-param-reassign
    date.onchange = () => {
      if (date.value !== '') {
        date.classList.add('default');
      } else {
        date.classList.remove('default');
      }
    };
  });
}
