const DISTRICTS = require('../../../assets/data/districts.json');

export function districtsForState(state) {
  return DISTRICTS.filter((obj) => obj.state === state);
}

export function districtOptionGroup(districts, selected = null) {
  let out = '<option value selected></option>';
  // eslint-disable-next-line no-return-assign
  districts.forEach((district) => out += `<option ${district.name === selected ? 'selected' : ''} value=${district.name}>${district.name}</option>`);
  return out;
}
