import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    event: String,
  };

  dispatchEvent() {
    this.dispatch(this.eventValue);
  }
}
