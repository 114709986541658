export default class FormSerializer {
  constructor(form, button) {
    this.initialForm = this.serializeForm(form);
    this.button = button;
    this.disableSubmit();
  }

  compareForms(formToInspect) {
    if (this.initialForm === this.serializeForm(formToInspect)) {
      this.disableSubmit();
    } else {
      this.enableSubmit();
    }
  }

  serializeForm(form) {
    this.formData = new FormData(form);
    const formValues = [...this.formData.entries()];
    // wipe authenticity token
    formValues[1] = null;
    return JSON.stringify(formValues);
  }

  disableSubmit() {
    this.button.classList.add('disabled');
    this.button.disabled = true;
  }

  enableSubmit() {
    this.button.classList.remove('disabled');
    this.button.disabled = false;
  }
}
