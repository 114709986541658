import { Controller } from 'stimulus';
import { FetchRequest } from '@rails/request.js';

export default class extends Controller {
  static values = {
    url: String,
    method: String,
  };

  async request() {
    const request = new FetchRequest(this.methodValue, this.urlValue);
    await request.perform();
  }
}
