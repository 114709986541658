import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'output', 'attribute'];

  static values = { textIdMap: Array, defaultText: String, defaultAttributes: Object };

  update() {
    const checkedItems = this.inputTargets.filter((item) => item.checked);
    const [text, options] = checkedItems.length ? this.getItemText(checkedItems) : this.defaults;
    if (Boolean(text) && this.hasOutputTarget) {
      this.setText(text);
      if (options) {
        Object.entries(options).forEach(([k, v]) => this.attributeTarget.setAttribute(k, v));
      }
    } else if (!text && Boolean(checkedItems[0].id)) {
      console.error(`Item with key "${checkedItems[0].id}" could not be found`);
    } else {
      console.error('Could not update text');
    }
  }

  getItemText(checkedItems) {
    const ids = checkedItems.map((i) => i.id);
    /* eslint-disable no-restricted-syntax */
    for (const [keys, value, options] of this.textIdMapValue) {
      if (keys.every((key) => ids.includes(key))) {
        return [value, options];
      }
    }
    /* eslint-enable no-restricted-syntax */

    return this.defaults;
  }

  get defaults() {
    return [this.defaultTextValue, this.defaultAttributesValue];
  }

  setText(value) {
    this.outputTarget.textContent = value;
  }
}
