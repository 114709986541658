import { Controller } from 'stimulus';
import { formatMoneyDecimal } from '../src/utils/currency';
import {
  singularDuration, DURATION_IN_MONTHS,
} from '../src/utils/datetime';

export default class extends Controller {
  static values = { stripeConnected: { type: Boolean, default: false }, stripeAuthUrl: String };

  static targets = ['header', 'footer', 'stripeConnected', 'flatFee', 'costFee', 'meteredFee', 'graduatedFee', 'discountFee', 'inclusiveTaxes', 'periodInput', 'period', 'customPeriodInput', 'total', 'totalModifier',
    'includeBilling', 'billingType', 'error', 'subscriptionPeriodAmount', 'subscriptionPeriodUnit', 'paymentProcessType', 'invoicePeriodUnit', 'invoicePeriodCustomUnit',
    'automaticPeriodUnit', 'effectiveDateType'];

  connect() {
    this.errorType = null;
    this.render();
  }

  render() {
    const usesMagicBill = this.stripeConnectedValue && this.usesStripeBilling();
    if (this.hasErrorTarget) {
      if (usesMagicBill) {
        const hasError = !this.validateFees();
        if (hasError) {
          this.setErrorText();
        }
        toggleVisibility(this.errorTarget, hasError);
      } else {
        toggleVisibility(this.errorTarget, false);
      }
    }
    if (this.hasHeaderTarget) {
      toggleVisibility(this.headerTarget, usesMagicBill);
    }
    if (this.hasFooterTarget) {
      toggleVisibility(this.footerTarget, !usesMagicBill);
      this.updateFooterText();
    }
    if (this.hasTotalTarget) {
      this.updateTotal();
    }
    if (this.hasTotalModifierTarget) {
      this.updateModifiers();
    }
    if (this.hasPeriodInputTarget) {
      this.updatePeriod();
    }
  }

  hasEffectiveDateMarketplace() {
    if (this.effectiveDateTypeTargets.length === 1) { // renewal using hidden field (with no checked attribute)
      return Boolean(this.effectiveDateTypeTarget.value === 'marketplace_acceptance');
    }
    return Boolean(this.effectiveDateTypeTargets.find((e) => e.checked === true && e.value === 'marketplace_acceptance'));
  }

  validateFees() {
    if (!this.hasStripeBillableFees) {
      this.errorType = 'no_stripe_billable_fees';
      return false;
    }
    if (this.hasEffectiveDateMarketplace()) {
      this.errorType = 'invcalculable_effective_date';
      return false;
    }
    if (this.periodInputTarget.value === 'User' || this.periodInputTarget.value === 'Custom unit') {
      this.errorType = 'invalid_fee_period';
      return false;
    }
    if (this.periodInputTarget.value === 'Subscription Period' && (this.subscriptionPeriodUnitTarget.value === 'day(s)' || this.subscriptionPeriodUnitTarget.value === 'week(s)')) {
      this.errorType = 'invalid_fee_period';
      return false;
    }
    if (this.paymentProcessType === 'invoice' && this.invoicePeriodUnitTarget.value === 'Custom unit') {
      this.errorType = 'custom_invoice_period';
      return false;
    }
    if (!this.validInvoiceSubscriptionPeriodRelationship()) {
      this.errorType = 'invalid_invoice_subscription_period_relationship';
      return false;
    }
    if (this.discountFeeTargets.filter(isActive).length > 1) {
      this.errorType = 'multiple_discounts';
      return false;
    }
    const total = this.calculateTotal();
    if (total <= 0 && !this.hasMeteredFees) {
      this.errorType = 'non_positive_fee';
      return false;
    } if (total >= 1000000) {
      this.errorType = 'fee_too_large';
      return false;
    }
    this.errorType = null;
    return true;
  }

  setErrorText() {
    let text = '';
    switch (this.errorType) {
      case 'no_stripe_billable_fees':
        text = "We can't determine the fees to charge in Stripe from the fees selected.";
        break;
      case 'invalid_fee_period':
        text = 'Our Stripe integration currently only supports monthly or yearly billing.';
        break;
      case 'non_positive_fee':
        text = 'Stripe only supports subscriptions greater than $0.';
        break;
      case 'fee_too_large':
        text = "Stripe doesn't support subscriptions larger than $1,000,000.";
        break;
      case 'custom_invoice_period':
        text = "Our Stripe integration doesn't currently support an Invoice Period with Custom unit.";
        break;
      case 'invalid_invoice_subscription_period_relationship':
        text = 'Stripe needs the invoice frequency to be shorter than the subscription length.';
        break;
      case 'multiple_discounts':
        text = "Stripe doesn't support multiple discounts.";
        break;
      case 'invcalculable_effective_date':
        text = "Our Stripe integration doesn't currently support an Effective Date from a Marketplace.";
        break;
      default:
        break;
    }
    this.errorTarget.querySelector('.banner-message').textContent = text;
  }

  validInvoiceSubscriptionPeriodRelationship() {
    const subscriptionPeriod = this.subscriptionPeriodWithMultiple();
    const invoicePeriod = this.invoicePeriod();
    const subscriptionPeriodMonths = DURATION_IN_MONTHS[subscriptionPeriod.unit] * subscriptionPeriod.multiple;
    const invoicePeriodMonths = DURATION_IN_MONTHS[invoicePeriod];
    return invoicePeriodMonths <= subscriptionPeriodMonths;
  }

  subscriptionPeriodBySelection() {
    const period = this.periodInputTarget.value;
    if (period === 'Subscription Period') {
      return this.subscriptionPeriodWithMultiple();
    }
    return { unit: period, multiple: 1 };
  }

  subscriptionPeriodWithMultiple() {
    const unit = singularDuration(this.subscriptionPeriodUnitTarget.value);
    const multiple = Number(this.subscriptionPeriodAmountTarget.value);
    return { unit, multiple };
  }

  invoicePeriod() {
    switch (this.paymentProcessType) {
      case 'automatic':
        return this.automaticPeriodUnitTarget.value;
      case 'invoice':
        return this.invoicePeriodUnit();
      default:
        console.error(`Unexpected invoice period value: ${this.paymentProcessType}`);
        return null;
    }
  }

  invoicePeriodUnit() {
    const unit = this.invoicePeriodUnitTarget.value;
    return unit === 'Custom unit' ? this.invoicePeriodCustomUnitTarget.value : unit;
  }

  updateFooterText() {
    let text = '';
    const billingWorkflowLink = '<a data-turbolinks="false" href="#billing-workflow">Manage billing workflow</a>';
    if (!this.stripeConnectedValue) {
      if (this.validateFees()) {
        text = `Compatible with MagicBill. <a href="${this.stripeAuthUrlValue}" target=_blank>Connect to Stripe</a> to bill automatically.`;
      } else {
        text = `<a href="${this.stripeAuthUrlValue}" target=_blank>Connect to Stripe</a> to get started with automatic billing.`;
      }
    } else if (this.hasBillingWorkflow) {
      if (this.billingWorkflowType === 'link') {
        text = `A payment link will be sent after signing. ${billingWorkflowLink}.`;
      }
    } else {
      text = `No billing workflow included. ${billingWorkflowLink}.`;
    }
    this.footerTarget.innerHTML = `<p>${text}</p>`;
  }

  updateTotal() {
    this.totalTarget.textContent = formatMoneyDecimal(this.calculateTotal());
  }

  calculateTotal() {
    const subscriptionPeriod = this.subscriptionPeriodBySelection();
    const invoicePeriod = this.invoicePeriod();
    const subscriptionPeriodMonths = DURATION_IN_MONTHS[subscriptionPeriod.unit] * subscriptionPeriod.multiple;
    const invoicePeriodMonths = DURATION_IN_MONTHS[invoicePeriod];
    if (subscriptionPeriodMonths === 0 || Number.isNaN(subscriptionPeriodMonths) || !invoicePeriodMonths) {
      return 0;
    }
    const multiple = subscriptionPeriodMonths / invoicePeriodMonths;
    let total = this.flatFeeTargets.filter(isActive).map(flatFeeTotal).reduce(add, 0);
    total += this.costFeeTargets.filter(isActive).map(costFeeTotal).reduce(add, 0);
    total /= multiple;
    return total - this.discountFeeTargets.filter(isActive).map((f) => discountFeeTotal(f, total)).reduce(add, 0);
  }

  updateModifiers() {
    if (this.hasMeteredFees) {
      if (this.hasInclusiveTaxes) {
        this.setTotalModifier('plus metered items');
      } else {
        this.setTotalModifier('plus taxes and metered items');
      }
    } else if (!this.hasInclusiveTaxes) {
      this.setTotalModifier('plus taxes');
    } else {
      this.setTotalModifier(null);
    }
  }

  setTotalModifier(text) {
    this.totalModifierTarget.textContent = text;
  }

  updatePeriod() {
    this.periodTarget.textContent = this.invoicePeriod();
  }

  stripeConnectedTargetConnected() {
    this.stripeConnectedValue = true;
    this.render();
  }

  usesStripeBilling() {
    return this.hasBillingWorkflow && (this.billingWorkflowType === 'stripe');
  }

  get billingPeriod() {
    const periodInput = this.periodInputTarget.value;
    if (periodInput === 'Custom unit') {
      return this.customPeriodInputTarget.value;
    }
    return periodInput;
  }

  get hasInclusiveTaxes() {
    return this.hasInclusiveTaxesTarget && this.inclusiveTaxesTarget.checked;
  }

  get hasStripeBillableFees() {
    return this.hasFlatFees || this.hasCostFees || this.hasMeteredFees;
  }

  get hasFlatFees() {
    return this.flatFeeTargets.some(isActive);
  }

  get hasCostFees() {
    return this.costFeeTargets.some(isActive);
  }

  get hasMeteredFees() {
    return this.meteredFeeTargets.some(isActive) || this.graduatedFeeTargets.some(isActive);
  }

  get hasBillingWorkflow() {
    return this.includeBillingTarget.checked;
  }

  get billingWorkflowType() {
    return this.billingTypeTargets.find((i) => i.checked)?.value;
  }

  get paymentProcessType() {
    return this.paymentProcessTypeTargets.find((i) => i.checked)?.value;
  }
}

function isActive(f) {
  return !f.parentElement.classList.contains('hidden') && !f.classList.contains('hidden');
}

function toggleVisibility(target, shouldShow) {
  if (shouldShow) {
    target.classList.remove('hidden');
  } else {
    target.classList.add('hidden');
  }
}

function parseNumber(value) {
  return Number(value.replace(/[^0-9.-]+/g, ''));
}

function costFeeTotal(fee) {
  const cost = parseNumber(fee.querySelector('[id$=cost]').value);
  const quantity = parseNumber(fee.querySelector('[id$=quantity]').value);
  return cost * quantity;
}

function flatFeeTotal(fee) {
  return parseNumber(fee.querySelector('[id$=cost]').value);
}

// This method needs to take the existing total because percentage discounts are based on total.
function discountFeeTotal(fee, total) {
  const discountType = fee.querySelector('input[type="radio"]:checked').value;
  if (discountType === 'fixed_amount') {
    return parseNumber(fee.querySelectorAll('[id$=cost]')[0].value);
  }
  if (discountType === 'percentage') {
    return total * (parseNumber(fee.querySelectorAll('[id$=cost]')[1].value) / 100.0);
  }

  console.error(`Unexpected discountType ${discountType}`);
  return 0;
}

function add(a, b) {
  return a + b;
}
