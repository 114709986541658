import { Controller } from 'stimulus';
import SYMBOLS from '../../assets/data/currency_symbols.json';

export default class extends Controller {
  static targets = ['input', 'symbol'];

  symbol() {
    return SYMBOLS[this.inputTarget.value];
  }

  symbolTargetConnected(target) {
    // eslint-disable-next-line no-param-reassign
    target.innerHTML = this.symbol();
  }

  updateSymbols() {
    this.symbolTargets.forEach((target) => {
      // eslint-disable-next-line no-param-reassign
      target.innerHTML = this.symbol();
    });
  }
}
