document.addEventListener('turbolinks:load', () => {
  const selectors = document.querySelectorAll('.select-show-or-hide');

  if (selectors) {
    selectors.forEach((selector) => {
      const elementToHide = document.querySelector(`#${selector.dataset.target}`);
      selector.addEventListener('change', () => {
        if (!elementToHide) {
          console.error(`Element with data target ${selector.dataset.target} not found`);
        } else if (selector.value === 'Custom unit') {
          elementToHide.classList.remove('field-hide-display');
        } else {
          elementToHide.classList.add('field-hide-display');
        }
      });
    });

    $(document).ready(() => {
      selectors.forEach((selector) => {
        const elementToHide = document.querySelector(`#${selector.dataset.target}`);
        if (!elementToHide) {
          console.error(`Element with data target ${selector.dataset.target} not found`);
        } else if (selector.value === 'Custom unit') {
          elementToHide.classList.remove('field-hide-display');
        } else {
          elementToHide.classList.add('field-hide-display');
        }
      });
    });
  }
});
