import { Controller } from 'stimulus';

function attrTypeMapping(attr) {
  return {
    recipient_organization: 'string',
    recipient_name: 'string',
    sender_signer_name: 'string',
    owner_name: 'string',
    all_signed_date: 'date',
    effective_date: 'date',
    end_date: 'date',
    governing_law_region: 'string',
    chosen_courts_region: 'string',
    subsidiary_id: 'select',
  }[attr];
}

export default class extends Controller {
  static values = { kind: String, url: Object };

  static targets = ['attr', 'condition', 'value'];

  static outlets = ['autosave'];

  disconnect() {
    if (this.hasAutosaveOutlet) {
      this.autosaveOutlet.save();
    }
  }

  update() {
    const attrType = attrTypeMapping(this.attrTarget.value);
    if (attrType === 'string' && this.kindValue !== 'string') {
      this.kindValue = 'string';
      this.updateConditionString();
      this.updateValueString();
    } else if (attrType === 'date' && this.kindValue !== 'date') {
      this.kindValue = 'date';
      this.updateConditionDate();
      this.updateValueDate();
    } else if (attrType === 'select' && this.kindValue !== 'select') {
      this.kindValue = 'select';
      this.updateConditionSelect();
      this.getSelectOptions();
    }
    if (this.hasAutosaveOutlet) {
      this.autosaveOutlet.save();
    }
  }

  updateConditionString() {
    this.conditionTarget.innerHTML = '<option>contains</option><option>does not contain</option><option>matches</option>';
    this.conditionTarget.classList.remove('hidden');
  }

  updateConditionDate() {
    this.conditionTarget.innerHTML = '<option>later than or equal to</option><option>later than</option><option>equals</option><option>earlier than</option><option>earlier than or equal to</option>';
    this.conditionTarget.classList.remove('hidden');
  }

  updateValueString() {
    this.valueTarget.outerHTML = '<input type="text" name="value[]" id="value_" value="" class="w-3" autocomplete="off" data-custom-filter-target="value" data-action="autosave#save">';
  }

  updateValueDate() {
    this.valueTarget.outerHTML = '<input type="date" name="value[]" id="value_" value="" class="w-3" autocomplete="off" data-custom-filter-target="value" data-action="autosave#save"></input>';
  }

  updateConditionSelect() {
    this.conditionTarget.innerHTML = '<option></option>';
    this.conditionTarget.classList.add('hidden');
  }

  updateValueSelect(options) {
    const optionElements = options.map((option) => `<option value=${option[1]}>${option[0]}</option>`).join('');
    this.valueTarget.outerHTML = `<select type="date" name="value[]" id="value_" class="w-3 form-select" autocomplete="off" data-custom-filter-target="value" data-action="autosave#save">${optionElements}</select>`;
  }

  async getSelectOptions() {
    const urls = this.urlValue;
    const response = await fetch(urls[this.attrTarget.value], {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    });
    const options = await response.json();
    this.updateValueSelect(options.map((option) => [option.name, option.id]));
  }
}
