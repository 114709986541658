/* eslint-disable import/prefer-default-export */

export function formatMoneyDecimal(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    roundingIncrement: 1,
  });
  return formatter.format(amount);
}

export function parseMoneyInput(amount) {
  return parseFloat(amount.replace(/[,|$]/g, ''));
}
