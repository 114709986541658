import { Autocomplete } from 'stimulus-autocomplete';

export default class CpAutocomplete extends Autocomplete {
  buildURL(query) {
    const url = new URL(this.urlValue, window.location.href);
    const params = new URLSearchParams(url.search.slice(1));
    if (isJsonObject(query)) {
      const data = JSON.parse(query);
      Object.keys(data).forEach((k) => {
        params.append(k, data[k]);
      });
    } else {
      params.append('q', query);
    }
    url.search = params.toString();

    return url.toString();
  }
}

function isJsonObject(value) {
  try {
    const json = JSON.parse(value);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
}
