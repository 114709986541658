import FormSerializer from './utils/serialize_form';

document.addEventListener('turbolinks:load', () => {
  const form = document.querySelector('.agreement_edit_form');
  const button = document.querySelector("input[name='negotiate_changes']");
  if (button && form) {
    const formSerializer = new FormSerializer(form, button);
    const districtSelector = $('[data-districts]');
    const stateSelector = $('[data-jurisdiction]');
    const governingLawSelector = $('[data-governing]');
    const attachmentSelectors = $('.attachment-selectors');
    const removeButton = $('.remove');
    form.addEventListener('input', () => formSerializer.compareForms(form));
    stateSelector.on('select2:select', () => formSerializer.compareForms(form));
    districtSelector.on('select2:select', () => formSerializer.compareForms(form));
    districtSelector.on('select2:clear', () => formSerializer.compareForms(form));
    governingLawSelector.on('select2:select', () => formSerializer.compareForms(form));
    attachmentSelectors.each((i, selector) => {
      $(selector).on('select2:select', () => formSerializer.compareForms(form));
    });
    removeButton.on('click', () => formSerializer.compareForms(form));
    document.addEventListener('click', (e) => {
      if (e.target.className === 'remove') {
        formSerializer.compareForms(form);
      }
    });
  }
});
