/* global Cleave */
/* eslint no-new: "off" */

import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    numeral: { type: Boolean, default: true },
    numeralThousandsGroupStyle: { type: String, default: 'thousand' },
    rawValueTrimPrefix: { type: Boolean, default: true },
    numeralDecimalScale: { type: Number, default: 2 },
    numeralPositiveOnly: { type: Boolean, default: false },
  };

  connect() {
    this.cleave = new Cleave(this.element, this.options());
  }

  options() {
    return {
      numeral: this.numeralValue,
      numeralThousandsGroupStyle: this.numeralThousandsGroupStyleValue,
      rawValueTrimPrefix: this.rawValueTrimPrefixValue,
      numeralDecimalScale: this.numeralDecimalScaleValue,
      numeralPositiveOnly: this.numeralPositiveOnlyValue,
    };
  }
}
