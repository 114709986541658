import { Controller } from 'stimulus';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Form from '@uppy/form';
import XHRUpload from '@uppy/xhr-upload';

function uppyConfig(fileType) {
  const uppyRestrictionsBasedOnType = {
    attachment: {
      allowedFileTypes: ['application/pdf'],
      maxFileSize: 10000000,
      minFileSize: 1,
    },
    image: {
      allowedFileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      maxFileSize: 5000000,
      minFileSize: 1,
    },
    unsigned: {
      allowedFileTypes: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'],
      maxFileSize: 10000000,
      minFileSize: 1,
    },
  };

  return {
    allowMultipleUploads: false,
    restrictions: {
      ...uppyRestrictionsBasedOnType[fileType],
      maxNumberOfFiles: 1,
    },
  };
}

function createImagePreview(imageTarget, result) {
  const { data } = result; // is a Blob instance
  const url = URL.createObjectURL(data);
  const image = new Image();
  image.src = url;
  imageTarget.replaceChildren(image);
}

function openPdfPreview(dataUrl) {
  const newWindow = window.open();
  newWindow.document.write(`<iframe src="${dataUrl}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`);
}

function attachmentPreview(name) {
  return `<div data-uppy-target="preview" class="attachment-preview-modal">
      <span>${name}</span>
      <span>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5858 5H11V3L17 3V9H15V6.41418L10.7071 10.7071L9.29285 9.29291L13.5858 5Z" fill="#326F84"/>
          <path d="M4 4L8 4V6H6L6 14L14 14V12H16V16L4 16L4 4Z" fill="#326F84"/>
        </svg>
      </span>
    </div>`;
}

function updateAttachmentSelectors(attachment) {
  const attachmentSelectors = $('.attachment-selectors');
  attachmentSelectors.each((i, selector) => {
    const setDefault = selector.getAttribute('data-target') === attachment.target;
    $(selector).append(new Option(attachment.attachment_name, attachment.attachment_id, false, setDefault));
    if (setDefault) $(selector).trigger('select2:select');
  });
}

export default class extends Controller {
  static values = {
    endpoint: String, fieldName: String, fileType: String, inline: Boolean,
  };

  static targets = ['error', 'dashboard', 'form', 'output', 'preview', 'title', 'image'];

  static outlets = ['selector'];

  connect() {
    this.uppy = new Uppy(uppyConfig(this.fileTypeValue))
      .use(Dashboard, this.dashboard_config())
      .use(Form, this.form_config())
      .use(XHRUpload, this.upload_config());

    this.uppy.on('complete', (result) => {
      if ((result.successful.length === 0) && (result.failed.length === 0)) {
        this.formTarget.submit();
      }
    });

    this.uppy.on('file-added', (result) => {
      const event = new CustomEvent('uppy-file-added');
      window.dispatchEvent(event);
      this.createFilePreview(result);
      this.setFileTitle(result);
      this.dashboardTarget.classList.add('hidden');
    });

    this.uppy.on('upload-success', (_, response) => {
      const event = new CustomEvent('uppy-upload-success', { detail: { success: true } });
      window.dispatchEvent(event);
      if (this.inlineValue) {
        updateAttachmentSelectors(response.body);
      } else {
        window.location.replace(response.body.redirect_to);
      }
    });

    this.uppy.on('info-visible', () => {
      const event = new CustomEvent('uppy-error');
      const { info } = this.uppy.getState();
      if (info.length && info[0].type === 'error') {
        window.dispatchEvent(event);
        this.errorTarget.innerHTML = info[0].message;
      }
    });

    this.uppy.on('upload-error', (file, _, response) => {
      const { message } = response.body;
      const event = new CustomEvent('uppy-error');
      window.dispatchEvent(event);
      this.previewTarget.remove();
      this.uppy.reset();
      this.uppy.addFile(file);
      if (message.length < 2) this.errorTarget.innerHTML = message;
      else {
        const listItems = message.map((item) => `<li>${item}</li>`);
        const listString = listItems.join('');
        this.errorTarget.innerHTML = `<ul class="pl-6">${listString}</ul>`;
      }
    });
  }

  reset() {
    this.uppy.reset();
    this.dashboardTarget.classList.remove('hidden');
    this.outputTarget.classList.add('hidden');
  }

  disconnect() {
    this.uppy.reset();
    this.dashboardTarget.classList.remove('hidden');
    this.uppy.close();
  }

  dashboard_config() {
    return {
      proudlyDisplayPoweredByUppy: false,
      target: this.dashboardTarget,
      disableThumbnailGenerator: true,
      inline: true,
      hideUploadButton: true,
      disableStatusBar: true,
      hideProgressAfterFinish: true,
      locale: {
        strings: {
          dropPasteFiles: 'or drop here to upload %{browseFiles}',
          browseFiles: 'Select file',
        },
      },
    };
  }

  form_config() {
    return {
      target: this.formTarget,
      addResultToForm: false,
      triggerUploadOnSubmit: true,
    };
  }

  upload_config() {
    return {
      method: 'post',
      headers: { Accept: 'application/json' },
      formData: true,
      fieldName: this.fieldNameValue,
      endpoint: this.endpointValue,
    };
  }

  createFilePreview(result) {
    if (this.fileTypeValue === 'attachment' || this.fileTypeValue === 'unsigned') {
      this.createAttachmentPreview(result);
    } else {
      createImagePreview(this.imageTarget, result);
    }
  }

  setFileTitle(result) {
    if (this.hasTitleTarget) {
      const fileName = result.data.name;
      this.titleTarget.value = fileName.substring(0, fileName.lastIndexOf('.'));
    }
  }

  createAttachmentPreview(result) {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => this.updateAttachmentPreview(event, result), false);
    reader.readAsDataURL(result.data);
  }

  updateAttachmentPreview(event, result) {
    this.outputTarget.innerHTML = attachmentPreview(result.data.name) + this.outputTarget.innerHTML;
    const reader = event.target;
    $('.attachment-preview-modal').on('click', () => openPdfPreview(reader.result));
  }
}
