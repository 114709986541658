import oneOrMultipleValidation from './utils/one_or_multiple_validation';

class FrontEndFormValidator {
  constructor() {
    this.createFormValidationHandler();
  }

  createFormValidationHandler() {
    const formNode = document.querySelector('.form-with-validation');
    if (formNode) {
      formNode.addEventListener('submit', (event) => this.handleOneOrMultipleValidation(event));
    }
  }

  handleOneOrMultipleValidation(event) {
    this.checkboxGroups = document.querySelectorAll('.one_or_multiple_checkbox_group');
    if (this.checkboxGroups.length) {
      oneOrMultipleValidation(this.checkboxGroups, event);
    }
  }
}

window.addEventListener('turbolinks:load', () => new FrontEndFormValidator());
