import { Controller } from 'stimulus';
import { useClickOutside } from 'stimulus-use';
import {
  onArrowDownKeydown, onArrowUpKeydown, onEnterKeydown, onEscapeKeydown, onKeydown, onTabKeydown,
} from '../src/utils/keyboard_shortcuts';

const activeSelector = "[aria-selected='true']";

export default class extends Controller {
  static values = { placeholder: String };

  static targets = ['button', 'box', 'hidden', 'item', 'text'];

  connect() {
    this.close();

    if (!this.hasButtonTarget) return;

    this.buttonTarget.addEventListener('click', this.toggle);
    this.buttonTarget.addEventListener('keydown', this.onKeydown);
    useClickOutside(this);
  }

  disconnect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener('keydown', this.onKeydown);
      this.buttonTarget.removeEventListener('click', this.toggle);
    }
    if (this.hasItemTargets) {
      this.itemTargets.forEach((item) => {
        item.removeEventListener('click', (event) => this.onCommit(event.target));
      });
    }
  }

  reset(event) {
    if (this.hasPlaceholderValue) {
      this.hiddenTarget.value = null;
      this.element.classList.remove('active');
      if (this.hasTextTarget) {
        this.textTarget.innerHTML = this.placeholderValue;
      }
      this.buttonTarget.querySelector('#icon-dropdown')?.classList.remove('hidden');
      this.buttonTarget.querySelector('#icon-reset')?.classList.add('hidden');
      this.hiddenTarget.dispatchEvent(new Event('change'));
    } else {
      this.onCommit(this.itemTargets[0]);
    }
    if (event) {
      event.stopPropagation();
    }
  }

  itemTargetConnected(target) {
    target.addEventListener('click', (event) => this.onCommit(event.target));
  }

  clickOutside() {
    this.close();
  }

  close() {
    if (!this.expanded) return;

    this.expanded = false;
    this.element.setAttribute('aria-expanded', 'false');
    this.buttonTarget.removeAttribute('aria-activedescendant');
    this.boxTarget.classList.add('hidden');
  }

  open() {
    if (this.expanded) return;

    this.expanded = true;
    this.element.setAttribute('aria-expanded', 'true');
    this.boxTarget.classList.remove('hidden');
  }

  toggle = () => {
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  };

  sibling(next) {
    const options = this.itemTargets;
    const selected = this.selectedOption;
    const index = options.indexOf(selected);
    const sibling = next ? options[index + 1] : options[index - 1];
    const def = next ? options[0] : options[options.length - 1];
    return sibling || def;
  }

  onKeydown = (event) => onKeydown(this, event);

  onEscapeKeydown = (event) => onEscapeKeydown(this, event);

  onArrowDownKeydown = (event) => onArrowDownKeydown(this, event);

  onArrowUpKeydown = (event) => onArrowUpKeydown(this, event);

  onTabKeydown = () => onTabKeydown(this);

  onEnterKeydown = (event) => onEnterKeydown(this, event);

  onCommit = (target) => {
    if (this.hasTextTarget) {
      this.textTarget.innerHTML = target.innerHTML;
    }
    this.hiddenTarget.value = target.getAttribute('data-value');
    this.hiddenTarget.dispatchEvent(new Event('change'));
    this.element.classList.add('active');
    if (this.hasPlaceholderValue) {
      this.buttonTarget.querySelector('#icon-dropdown')?.classList.add('hidden');
      this.buttonTarget.querySelector('#icon-reset')?.classList.remove('hidden');
    }
    this.close();
  };

  get selectedOption() {
    return this.boxTarget.querySelector(activeSelector);
  }
}
