export const onSelect = (controller, target) => {
  const previouslySelected = controller.selectedOption;
  if (previouslySelected) {
    previouslySelected.removeAttribute('aria-selected');
    previouslySelected.classList.remove('active');
  }

  target.setAttribute('aria-selected', 'true');
  target.classList.add('active');
  controller.buttonTarget.setAttribute('aria-activedescendent', target.id);
  target.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
};

export const onKeydown = (controller, event) => {
  const handler = controller[`on${event.key}Keydown`];
  if (handler) handler(event);
};

export const onEscapeKeydown = (controller, event) => {
  if (!controller.expanded) return;

  controller.close();
  event.stopPropagation();
  event.preventDefault();
};

export const onArrowDownKeydown = (controller, event) => {
  const item = controller.sibling(true);
  if (item) onSelect(controller, item);
  event.preventDefault();
};

export const onArrowUpKeydown = (controller, event) => {
  const item = controller.sibling(false);
  if (item) onSelect(controller, item);
  event.preventDefault();
};

export const onTabKeydown = (controller) => {
  const selected = controller.selectedOption;
  if (selected) controller.onCommit(selected);
};

export const onEnterKeydown = (controller, event) => {
  const selected = controller.selectedOption;
  if (selected && controller.expanded) {
    controller.onCommit(selected);
    event.preventDefault();
  }
};
