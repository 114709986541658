/* eslint no-console: ["warn", { allow: ["error"] }] */
import { Controller } from 'stimulus';
import { longdate } from '../src/utils/datetime';

function isToday(someDate) {
  const today = new Date();
  return today.toDateString() === someDate.toDateString();
}

export default class extends Controller {
  static values = { format: { type: String, default: 'date' }, isFormatted: { type: Boolean, default: false } };

  connect() {
    if (this.isFormattedValue) {
      return;
    }
    let formatted;
    let date;
    let options;
    switch (this.formatValue) {
      case 'date':
        date = new Date(this.element.textContent);
        if (isToday(date)) {
          formatted = 'Today';
        } else {
          formatted = longdate(date);
        }
        break;
      case 'datetime': {
        date = new Date(this.element.textContent);
        options = {
          year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit',
        };
        const localeTimeString = date.toLocaleTimeString(undefined, options).split(',');
        formatted = `${localeTimeString[0]}, ${localeTimeString[1]}`;
        break;
      }
      case 'time':
        date = new Date(this.element.textContent);
        options = { hour: 'numeric', minute: '2-digit' };
        formatted = date.toLocaleTimeString(undefined, options);
        break;
      default:
        console.error(`unexpected date-format ${this.formatValue}. Expected one of 'date', 'datetime' or 'time`);
        return;
    }
    this.element.textContent = formatted;
    this.isFormattedValue = true;
  }
}
