import { Controller } from 'stimulus';
import HelloSign from 'hellosign-embedded';
/* eslint no-await-in-loop: "off", class-methods-use-this: "off" */

function statusCodes() {
  return [200, 201, 202, 203, 204, 205, 206, 207, 208, 226];
}

function conditionTest(result, retryCount) {
  return (result === null || !statusCodes().includes(result.status)) && (retryCount < 10);
}

function wait(ms = 5000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function calculateDelay(retryCount) {
  if (retryCount < 5) {
    return '1000';
  }
  if (retryCount <= 9) {
    return '3000';
  }

  return '10000';
}

function fetchSignature(signatureLink) {
  return fetch(signatureLink);
}

function executeHelloSign(urls, button, environment, containerTarget = false) {
  const client = new HelloSign();
  const skipDomainVerification = environment !== 'production';
  const signUrl = urls.hellosign.embedded.sign_url;
  const redirectUrl = urls.success_url;

  client.open(signUrl, {
    clientId: process.env.HELLOSIGN_CLIENT_ID,
    skipDomainVerification,
    container: containerTarget,
  });

  loadingSpinner(button, true);
  intercomButton(false);

  client.on('sign', () => {
    window.location.replace(redirectUrl);
  });

  client.on('close', () => {
    intercomButton(true);
    loadingSpinner(button, false);
  });
}

async function pollSignatureLink(fnCondition, signatureLink, environment) {
  let result = await fetchSignature(signatureLink);
  let retryCount = 0;
  const button = document.querySelector('.pending_sign_button');
  loadingSpinner(button, true);

  while (fnCondition(result, retryCount)) {
    await wait(calculateDelay(retryCount));
    result = await fetchSignature(signatureLink);
    retryCount += 1;
  }

  const jsonResponse = await result.json();
  executeHelloSign(jsonResponse, button, environment);
}

function intercomButton(shouldHide) {
  // Hidden in embedded flow because it hides
  // an important button on mid-size screens
  if (shouldHide) {
    $('.intercom-lightweight-app').removeClass('hide-content');
  } else {
    $('.intercom-lightweight-app').addClass('hide-content');
  }
}

function loadingSpinner(button, shouldDisable) {
  if (button) {
    const i = document.querySelector('.spinner-target');
    const signText = document.querySelector('.sign-text');
    /* eslint-disable no-param-reassign */
    if (shouldDisable) {
      button.disabled = true;
      i.classList.add('loading-spinner');
      i.style.position = 'absolute';
      signText.style.visibility = 'hidden';
    } else {
      button.disabled = false;
      i.classList.remove('loading-spinner');
      signText.style.visibility = 'visible';
    }
  }
  /* eslint-enable no-param-reassign */
}

export default class extends Controller {
  static values = { link: Object, unsigned: Boolean, environment: String };

  static targets = ['container'];

  connect() {
    window.addEventListener('beforeunload', (event) => {
      event.stopImmediatePropagation();
    });

    if (this.unsignedValue) {
      executeHelloSign(this.linkValue, false, this.environmentValue, this.containerTarget);
    }
  }

  pollHelloSign(event) {
    const [, , xhr] = event.detail;
    try {
      const jsonResponse = JSON.parse(xhr.response);
      const signatureLink = jsonResponse.signature_link;
      const { env } = jsonResponse;
      pollSignatureLink(conditionTest, signatureLink, env);
      return true;
    } catch (e) {
      return false;
    }
  }
}
