import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button'];

  submit(e) {
    e.preventDefault();
    this.element.setAttribute('data-turbo', 'true');
    this.element.requestSubmit(this.buttonTarget);
    this.element.setAttribute('data-turbo', 'false');
  }
}
