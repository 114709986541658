import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { reveal: String };

  static targets = ['input', 'output', 'hideOutput', 'showOutput', 'term', 'termOutput'];

  change(event) {
    const target = this.hasInputTarget ? this.inputTarget : event.target;
    if (target.value === this.revealValue) {
      this.outputTarget.classList.remove('hidden');
    } else {
      this.outputTarget.classList.add('hidden');
    }
  }

  toggleChange(event) {
    const target = this.hasInputTarget ? this.inputTarget : event.target;
    if (target.value !== this.revealValue) {
      this.showOutputTarget.classList.remove('hidden');
      this.hideOutputTarget.classList.add('hidden');
    } else {
      this.showOutputTarget.classList.add('hidden');
      this.hideOutputTarget.classList.remove('hidden');
      this.updateTerm();
    }
  }

  updateTerm() {
    if (this.hasTermOutputTarget) {
      this.termOutputTarget.textContent = this.termTarget.value;
    }
  }
}
