class AddFields {
  constructor() {
    this.links = document.querySelectorAll('.add_fields');
    this.iterateLinks();
  }

  iterateLinks() {
    if (this.links.length === 0) return;
    // Loop over each link on the page. A page could have multiple nested forms.
    this.links.forEach((link) => {
      link.addEventListener('click', (e) => {
        AddFields.handleClick(link, e);
      });
    });
  }

  static handleClick(link, e) {
    if (!link || !e) return;
    e.preventDefault();
    const target = document.querySelector(`#${link.dataset.target}`);
    const time = new Date().getTime();
    const linkId = link.dataset.id;
    const regexp = linkId ? new RegExp(linkId, 'g') : null;
    const newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
    const form = $('.agreement_edit_form')[0];
    if (newFields) {
      if (link.dataset.insertType === 'second') {
        target.insertAdjacentHTML('afterend', newFields);
      } else if (link.dataset.insertType === 'last') {
        target.insertAdjacentHTML('beforeend', newFields);
      } else {
        target.insertAdjacentHTML('beforeend', newFields);
      }
      // Triggers our onChange serialization for negotiations
      if (form) form.dispatchEvent(new Event('input'));
      window.dispatchEvent(new Event('add-fields'));
    }
  }
}

window.addEventListener('turbolinks:load', () => new AddFields());
