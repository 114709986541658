import { Controller } from 'stimulus';
import SEARCH_TERMS from '../../assets/data/template_search.json';

export default class extends Controller {
  static targets = ['link', 'linkList', 'card', 'helpText', 'allAgreements'];

  static outlets = ['search-box'];

  connect() {
    const allAgreementNode = this.allAgreementsTarget;
    allAgreementNode.classList.add('secondary-link-active');
  }

  input(event) {
    this.search(event.target.value);
    this.handleBoldLinks(event.target);
    this.handleLinksEnabled(event.target.value);
  }

  select(event) {
    this.search(event.target.dataset.value);
    this.enableLinks();
    this.handleBoldLinks(event.target);
    this.searchBoxOutlet.clear();
  }

  search(searchTerm) {
    const normalizedSearchTerm = searchTerm?.trim().toLowerCase() || '';
    if (normalizedSearchTerm.length < 2) {
      this.showAllCards();
    } else {
      this.showSomeCards(normalizedSearchTerm);
    }
  }

  handleBoldLinks(targetNode) {
    this.linkTargets.forEach((link) => {
      if (shouldActivateLink(link, targetNode)) {
        link.classList.add('secondary-link-active');
      } else {
        link.classList.remove('secondary-link-active');
      }
    });
  }

  handleLinksEnabled(value) {
    if (value) {
      this.disableLinks();
    } else {
      this.enableLinks();
    }
  }

  disableLinks() {
    this.linkListTarget.classList.add('disable-children');
  }

  enableLinks() {
    this.linkListTarget.classList.remove('disable-children');
  }

  showAllCards() {
    this.cardTargets.forEach((card) => card.classList.remove('hidden'));
    this.hideHelpText();
  }

  hideAllCards() {
    this.cardTargets.forEach((card) => card.classList.add('hidden'));
  }

  showSomeCards(searchTerm) {
    const results = findResults(searchTerm);
    const cardsToShow = this.findMatches(results);
    if (cardsToShow) {
      this.handleCardVisibility(cardsToShow);
      this.handleHelpText(searchTerm);
    } else {
      this.hideAllCards();
      this.setHelpText(emptyStateHelpText(), true);
    }
  }

  findMatches(results) {
    if (!results) return null;
    return this.cardTargets.reduce((matches, card) => {
      if (results.types.includes(normalizedTypeName(card))) {
        return [...matches, normalizedTypeName(card)];
      }
      return matches;
    }, []);
  }

  handleCardVisibility(cardsToShow) {
    this.cardTargets.forEach((card) => {
      if (cardsToShow.includes(normalizedTypeName(card))) {
        card.classList.remove('hidden');
      } else {
        card.classList.add('hidden');
      }
    });
  }

  handleHelpText(searchTerm) {
    const helpText = findHelpText(searchTerm);
    if (helpText) {
      const boldTextNode = document.createElement('strong');
      boldTextNode.append(helpText.bold);
      const splitHelpText = helpText.text.split(helpText.bold);
      const textNode = document.createElement('p');
      textNode.append(splitHelpText[0]);
      textNode.append(boldTextNode);
      textNode.append(splitHelpText[1]);
      this.setHelpText(textNode);
    } else {
      this.hideHelpText();
    }
  }

  setHelpText(helpTextResult, empty = false) {
    this.helpTextTarget.innerHTML = null;
    this.helpTextTarget.append(helpTextResult);
    this.emptyStateSpacing(empty);
    this.helpTextTarget.classList.remove('hidden');
  }

  hideHelpText() {
    this.helpTextTarget.classList.add('hidden');
  }

  emptyStateSpacing(emptyState) {
    if (emptyState) {
      this.helpTextTarget.classList.remove('mt-7');
    } else {
      this.helpTextTarget.classList.add('mt-7');
    }
  }
}

function emptyStateHelpText() {
  const linkNode = document.createElement('a');
  const linkText = document.createTextNode('contact us');
  linkNode.appendChild(linkText);
  linkNode.title = 'Contact us';
  linkNode.href = 'https://commonpaper.com/support/#:~:text=For%20help%20using%20Common%20Paper,the%20Get%20in%20Touch%20form.';
  const otherText = document.createElement('p');
  otherText.append("We couldn't find a match. You can ");
  otherText.append(linkNode);
  otherText.append(' and tell us more about your use case.');
  return otherText;
}

function findResults(searchTerm) {
  return SEARCH_TERMS.searchResults.find((set) => set.searchTerm.find((term) => term.includes(searchTerm)));
}

function findHelpText(searchTerm) {
  return SEARCH_TERMS.helpText.find((set) => set.searchTerm.find((term) => searchTerm.startsWith(term)));
}

function shouldActivateLink(linkNode, targetNode) {
  const linkIsTarget = linkNode.dataset.value === targetNode.dataset.value;
  const linkIsAllAgreements = !linkNode.dataset.value;
  const targetIsBlank = !targetNode.dataset.value && !targetNode?.value;
  return linkIsTarget || (linkIsAllAgreements && targetIsBlank);
}

// eslint-disable-next-line consistent-return
function normalizedTypeName(card) {
  const type = card?.dataset?.type;

  if (!type) {
    console.error('Cannot filter template cards because card type is invalid');
  } else {
  // We normalize the type name because we have to use `software` rather than `software_license` in the marketing site
  // and we chose to keep the JSON files consistent
    return type.split('_', 2)[0];
  }
}
