document.addEventListener('turbolinks:load', () => {
  if ($('[data-formatted-date=false]').length) {
    $('[data-formatted-date=false]').each((i, dateObject) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date($(dateObject).text());
      const formattedDate = date.toLocaleDateString(undefined, options);
      $(dateObject).text(formattedDate);
      $(dateObject).attr('data-formatted-date', true);
    });
  }

  if ($('[data-formatted-datetime=false]').length) {
    $('[data-formatted-datetime=false]').each((i, dateObject) => {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit',
      };
      const date = new Date($(dateObject).text());
      const localeTimeString = date.toLocaleTimeString(undefined, options).split(',');
      const formattedDate = `${localeTimeString[0]}, at ${localeTimeString[1]}`;
      $(dateObject).text(formattedDate);
      $(dateObject).attr('data-formatted-datetime', true);
    });
  }

  if ($('[data-formatted-time=false]').length) {
    $('[data-formatted-time=false]').each((i, dateObject) => {
      const options = {
        hour: 'numeric', minute: '2-digit',
      };
      const date = new Date($(dateObject).text());
      const localeTimeString = date.toLocaleTimeString(undefined, options);
      $(dateObject).text(localeTimeString);
      $(dateObject).attr('data-formatted-time', true);
    });
  }
});
