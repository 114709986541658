document.addEventListener('turbolinks:load', () => {
  const checkBoxes = document.querySelectorAll('.disable_checkbox');
  if (checkBoxes.length) {
    checkBoxes.forEach((checkBox) => {
      const elementToDisable = document.querySelector(`#${checkBox.dataset.target}`);
      checkBox.addEventListener('click', () => {
        toggleDisable(elementToDisable, checkBox.checked);
      });
    });
  }
});

function toggleDisable(elementToToggle, checked) {
  const element = elementToToggle;
  if (checked) {
    element.disabled = true;
  } else {
    element.disabled = false;
  }
}
