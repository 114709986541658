import { districtsForState } from './utils/districts';

document.addEventListener('turbolinks:before-cache', () => {
  $('.select2-hidden-accessible').select2('destroy');
});

document.addEventListener('turbolinks:load', () => {
  function setCountyOptions(data, districtSelector) {
    const counties = data.filter((obj) => obj.type === 'county');
    if (counties.length) {
      const countyOptGroup = $("<optgroup label='Counties'>");
      counties.forEach((countyOption) => {
        $(countyOptGroup).append($(
          '<option>',
          {
            value: countyOption.name,
            text: countyOption.name,
          },
        ));
      });
      districtSelector.append($(countyOptGroup));
    }
  }

  function setFederalOptions(data, districtSelector) {
    const federal = data.filter((obj) => obj.type === 'federal');
    if (federal.length) {
      const federalOptGroup = $("<optgroup label='Federal'>");
      federal.forEach((federalOption) => {
        $(federalOptGroup).append($(
          '<option>',
          {
            value: federalOption.name,
            text: federalOption.name,
          },
        ));
      });
      if ($(federalOptGroup).children().length > 1) {
        districtSelector.append($(federalOptGroup));
      }
    }
  }

  function showOrHideDistrictSelector(districtSelector) {
    const jurisdictionDistrictContainer = document.querySelector('.jurisdiction_district_or_county_selector');
    if (!jurisdictionDistrictContainer) {
      return;
    }
    if (districtSelector.children().length === 1) {
      jurisdictionDistrictContainer.classList.add('field-hide-display');
    } else {
      jurisdictionDistrictContainer.classList.remove('field-hide-display');
    }
  }

  function resetDistrictOrCounty(districtSelector, districtList) {
    districtSelector.empty();
    districtSelector.prepend('<option></option>');
    setCountyOptions(districtList, districtSelector);
    setFederalOptions(districtList, districtSelector);
    showOrHideDistrictSelector(districtSelector);
  }

  function districtInState(district, selectedState, districtList) {
    return Boolean(districtList.find(({ state, name }) => state === selectedState && name === district));
  }

  /* eslint-disable no-shadow */
  if ($('[data-districts=true]').length) {
    const districtSelector = $('[data-districts]');
    const stateSelector = $('[data-jurisdiction]');
    const governingStateSelector = $('[data-governing]');
    const districtList = districtsForState(stateSelector.val());
    $(document).ready(() => {
      stateSelector.trigger('change');
      governingStateSelector.trigger('change');
      districtSelector.trigger('change');
      if (!districtInState(districtSelector.val(), stateSelector.val(), districtList)) {
        const districtList = districtsForState(stateSelector.val());
        resetDistrictOrCounty(districtSelector, districtList);
      }
    });

    stateSelector.on('change.select2', (e) => {
      const selectedState = e.target.value;
      if (!districtInState(districtSelector.val(), selectedState, districtList)) {
        const districtList = districtsForState(selectedState);
        resetDistrictOrCounty(districtSelector, districtList);
      }
    });

    stateSelector.select2({
      width: '100%',
      theme: 'bootstrap-5',
    });
    governingStateSelector.select2({
      width: '100%',
      theme: 'bootstrap-5',
    });
    districtSelector.select2({
      placeholder: 'Not specified',
      allowClear: true,
      width: '100%',
      theme: 'bootstrap-5',
    });
  }
  /* eslint-enable no-shadow */
});
