import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    insertLocation: { type: String, default: 'last' },
  };

  static targets = ['template', 'container'];

  add() {
    const clone = this.templateTarget.content.cloneNode(true);
    const time = new Date().getTime();
    /* eslint-disable no-restricted-syntax */
    for (const child of clone.children) {
      child.innerHTML = child.innerHTML.replace(/ID_PLACEHOLDER/g, time);
    }
    /* eslint-enable no-restricted-syntax */

    switch (this.insertLocationValue) {
      case 'last':
        this.containerTarget.appendChild(clone);
        break;
      default:
        console.error(`Unexpected insertLocationValue ${this.insertLocationValue}. Expected one of ['last']. Defaulting to 'last'.`);
        this.containerTarget.appendChild(clone);
        break;
    }
  }
}
