// eslint-disable-next-line no-param-reassign
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item', 'current'];

  static classes = ['toggle'];

  toggle(event) {
    const elementToToggle = event.target.closest("[data-class-toggle-target='item']");
    const elementCurrentlyToggled = this.currentTarget;
    const elementArray = [elementToToggle, elementCurrentlyToggled];
    elementCurrentlyToggled.dataset.classToggleTarget = 'item';
    elementToToggle.dataset.classToggleTarget = 'item current';
    elementArray.forEach((e) => {
      this.toggleClasses.forEach((className) => {
        e.classList.toggle(className);
      });
    });
  }
}
