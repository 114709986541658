/* eslint no-param-reassign: ["error", { "props": false }] */

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  static classes = ['loading'];

  renderSpinner() {
    this.itemTargets.forEach((item) => {
      item.disabled = true;
      const i = item.querySelector('i');
      const loadingText = item.querySelector('.loading-text');
      i.classList.add(this.loadingClass);
      i.style.position = 'absolute';
      loadingText.style.visibility = 'hidden';
    });
  }
}
