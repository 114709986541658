/* eslint-disable import/prefer-default-export */
export function longdate(date) {
  const toFormat = inputStringtoDate(date);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return toFormat.toLocaleDateString(undefined, options);
}

export function addPeriodToDate(unformattedDate, period, unformattedAmount) {
  const date = inputStringtoDate(unformattedDate);
  const amount = Number(unformattedAmount);
  switch (period) { // eslint-disable-line default-case
    case 'year(s)':
      date.setFullYear(date.getFullYear() + amount);
      break;
    case 'month(s)':
      date.setMonth(date.getMonth() + amount);
      break;
    case 'week(s)':
      date.setDate(date.getDate() + (amount * 7));
      break;
    case 'day(s)':
      date.setDate(date.getDate() + amount);
      break;
  }
  return date;
}

export function singularDuration(duration) {
  return {
    'year(s)': 'year',
    'month(s)': 'month',
    'week(s)': 'week',
    'day(s)': 'day',
  }[duration] || duration;
}

export const DURATION_IN_MONTHS = {
  year: 12,
  month: 1,
  annually: 12,
  semiannually: 6,
  quarterly: 3,
  monthly: 1,
};

export function inputStringtoDate(dateString) {
  return typeof dateString === 'string' ? new Date(dateString.replace(/-/g, '/')) : dateString;
}

function isLeapYear(year) {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
}

function getDaysInMonth(year, month) {
  return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

export function addMonths(date, n) {
  const originalDate = date.getDate();
  date.setDate(1);
  date.setMonth(date.getMonth() + n);
  date.setDate(Math.min(originalDate, getDaysInMonth(date.getFullYear(), date.getMonth())));
  return date;
}
